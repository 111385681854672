import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import introduce from "@/components/about/Introduce.vue";
import weAre from "@/components/about/WeAre.vue";
import clientsLists from "@/components/about/ClientsLists.vue";
import contactForm from "@/components/contact/ContactForm.vue";
export default {
  __name: 'Index',
  emits: ["clickContact"],
  setup(__props, {
    emit
  }) {
    const openContact = () => {
      emit("clickContact");
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(introduce), _createVNode(weAre), _createVNode(clientsLists), _createElementVNode("div", {
        class: "contactbutton",
        onClick: openContact
      }, "提交需求"), _createVNode(contactForm, {
        class: "contactform"
      })], 64);
    };
  }
};